<template>
    <a href="https://wa.link/c7gcz1" target="_blank">
        <div>
            <p>
                ¿Te interesa conocer más? <br>
                <span>¡Contáctanos por Whatsapp!</span>
            </p>
        </div>

        <picture>
            <source srcset="@/assets/whatsapp.webp" type="image/webp">
            <img src="@/assets/whatsapp.png" alt="link de whatsapp">
        </picture>
    </a>
</template>
<script>
export default {

}
</script>
<style scoped>
*,
*::after,
*::before {
    box-sizing: border-box;
}

picture {
    display: flex;
    width: fit-content;
    height: fit-content;
}

a {
    display: flex;
    position: fixed;

    width: fit-content;
    bottom: 9.66183574879227VW;
    right: 9.66183574879227VW;
}

p {
    color: #000;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;

    display: none;
    font-size: 3.3816425120772946VW;
    line-height: 111%;
}

span {
    font-weight: 600;
}

img {
    height: auto;

    width: 12.80193236714976VW;
}

@media (min-width: 768px) {
    a {
        width: 2.7604166666666665VW;
        bottom: 2.083333333333333VW;
        right: 2.083333333333333VW;
        gap: 0.5208333333333333VW;
        overflow: hidden;
        justify-content: right;
        transition: width 0.3s linear;
    }

    div {
        height: fit-content;
        overflow: hidden;
        margin: auto 0;
    }

    p {
        display: block;
        font-size: 0.7291666666666666VW;
        line-height: 111%;
        background-color: #FFF;
        margin: 0;
        padding: 0.26041666666666663VW 0.4166666666666667VW;
        border-radius: 0.5208333333333333VW;
        height: fit-content;
        text-wrap: nowrap;
        transform: translateX(100%);
        transition: transform 0.3s linear;
    }

    img {
        width: 2.7604166666666665VW;
    }

    a:hover {
        width: 14.270833333333332VW;
    }

    a:hover p {
        transform: translateX(0);
    }
}
</style>