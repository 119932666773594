<template>
  <div id="Home" class="homepage">
    <div class="section1">
      <!-- Header aqui -->
      <Navigator />
      <whatsapp />
      <div class="filabanner">
        <img class="bannerpc" src="../assets/bannerpc.png" alt="" />
        <img class="bannermv" src="../assets/bannermv.png" alt="" />
      </div>
      <div id="nosotros" class="filanosotros">
        <!-- Indicador seccion -->
        <div class="idsecc">
          <h5>01.</h5>
          <hr />
          <h5>¿Quiénes somos?</h5>
        </div>
        <!-- Bloque Quienes somos -->
        <div class="bloqueqs">
          <div class="col1-qs">
            <img class="imgqs1pc" src="../assets/imgnoso.png" alt="" />
          </div>
          <div class="col2-qs">
            <h2>¿Quiénes Somos?</h2>
            <h4>
              Aprende a jugar tenis, mejora tu condicion, tus habilidades deportivas y comparte un momento de diversión
              al aire libre.
              Somos una academia de tenis con más de 11 años de trayectoria en Hermosillo, Sonora.
            </h4>
            <h4>Tenemos clases para niños, jóvenes y adultos, desde principiantes hasta avanzados. Nuestros instructores
              están altamente calificados para enseñarte a jugar y competir de forma profesional. Contamos con 2
              unidades totalmente equipadas (Milla o Navarrete), ambas de la Universidad de Sonora. </h4>
            <p>
              <strong>Misión.</strong> Compartir nuestro gusto y pasión por el tenis, impartiendo clases y
              entrenamientos de primer nivel. Siempre buscando fomentar el máximo desempeño deportivo, ya sea a un nivel
              recreativo o competitivo.
            </p>
            <p>
              <strong>Visión.</strong> Convertirnos en una de las mejores académicas de nuestra región, reconocidas por
              la calidad de nuestro equipo docente y la proyección de nuevos talentos deportivos.
            </p>
            <img class="imgqs1mv" src="../assets/imgnosomv.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div id="academia" class="sectionservicios">
      <!-- Indicador seccion -->
      <div class="idsecc">
        <h5>02.</h5>
        <hr />
        <h5>Servicios</h5>
      </div>
      <h2 class="titservicios">Academia</h2>
      <p class="subservicios">
        Conoce las diferentes opciones para aprender tenis que tenemos para ti
      </p>
      <div class="slideservicios">
        <div class="pc">
          <carousel :perPage="4" navigationEnabled :paginationEnabled="false">
            <slide class="slide-item" v-for="(it, key) in items" :key="key">
              <div class="bloqueservicio">
                <img :src="it.image" alt="" />
                <h3 class="titservicio">{{ it.titulo }}</h3>
                <p class="txtservicio">
                  {{ it.descripcion }}
                </p>
              </div>
            </slide>
          </carousel>
        </div>

        <div class="bloqueservicio mo" v-for="(it, key) in itemsorted" :key="key">
          <img :src="it.image" alt="" />
          <h3 class="titservicio">{{ it.titulo }}</h3>
          <p class="txtservicio">
            {{ it.descripcion }}
          </p>
        </div>
        <div class="bloquevermas">
          <p @click="NArrays(items, itemsorted.length)" v-if="!hide">Ver más</p>
          <p @click="(itemsorted = items.slice(0, 2)) && (hide = false)" v-if="hide">
            Ver menos
          </p>
        </div>
      </div>
    </div>
    <div id="clase-de-prueba" class="sectioncontacto">
      <div class="col-s3 col1-s3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.5676284685733!2d-111.0013506069896!3d29.088919025612686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce840815537a2f%3A0x9f7f0454ddebcdc3!2sUnidad%20Deportiva%20UNISON!5e0!3m2!1ses!2smx!4v1657142279898!5m2!1ses!2smx"
          width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="col-s3 col2-s3">
        <!-- Indicador seccion -->
        <div class="idsecc">
          <h5>03.</h5>
          <hr />
          <h5>Contacto</h5>
        </div>
        <h2 class="titcontacto titc1">Ven a tu</h2>
        <h2 class="titcontacto titc2">Clase de prueba</h2>
        <h4 class="subcontacto">
          Contáctanos y agenda tu clase de prueba <span>¡gratis!</span>
        </h4>
        <div class="filadacon">
          <div class="col1-dc">
            <img src="../assets/iconophone.svg" alt="" />
            <p>Tel. <span>(662) 405 5628</span></p>
          </div>
          <div class="col2-dc">
            <img src="../assets/iconomail.svg" alt="" />
            <a href="mailto:contacto@playtennis.mx">
              <p>contacto@playtennis.mx</p>
            </a>
          </div>
        </div>
        <div class="filadaubi">
          <div class="col1-du">
            <img src="../assets/iconoubica.png" alt="" />
          </div>
          <div class="col2-du">
            <p class="nameubi">Canchas de Tenis UNISON</p>
            <p class="direubi">
              Calle de la Reforma s/n, 83000, Hermosillo, Sonora
            </p>
            <p class="nameubi">Unidad Deportiva Navarrete UNISON</p>
            <p class="direubi">
              Blvd. Juan Navarrete, Montebello, 83210, Hermosillo, Sonora
            </p>
          </div>
        </div>
        <div class="mapamv">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.5676284685733!2d-111.0013506069896!3d29.088919025612686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce840815537a2f%3A0x9f7f0454ddebcdc3!2sUnidad%20Deportiva%20UNISON!5e0!3m2!1ses!2smx!4v1657142279898!5m2!1ses!2smx"
            width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    <div id="Siguenos" class="sectionredes">
      <div class="idsecc">
        <h5>03.</h5>
        <hr />
        <h5>Redes sociales</h5>
      </div>
      <div class="bloqueredes">
        <h2>Síguenos</h2>
        <div class="fila-br">
          <img class="img1br" src="../assets/imagebr1.png" alt="" />
          <img class="img2br" src="../assets/imagebr2.png" alt="" />
        </div>
        <div class="fila-br">
          <img class="img1br" src="../assets/imagebr3.png" alt="" />
          <img class="img2br" src="../assets/imagebr4.png" alt="" />
        </div>
        <a href="https://www.instagram.com/playtennismx">
          <div class="btnig">
            <h5>@PlayTennisMX</h5>
          </div>
        </a>
      </div>
    </div>
    <div class="sectionbanner2">
      <img class="imgban2mv" src="../assets/banner2mv.png" alt="" />
      <img class="imgban2pc" src="../assets/banner2pc.png" alt="" />
    </div>
    <form id="contacto" @submit.prevent="enviarMail(name, phone, email, mensaje)">
      <div class="sectionformulario">
        <h2 class="titform">Contacto</h2>
        <div class="bloqueform">
          <input class="inputbf inputname" type="text" placeholder="Nombre" v-model="name" />
          <input class="inputbf inputnumber" type="number" placeholder="Celular" v-model="phone" />
          <input class="inputbf inputemail" type="email" placeholder="Correo" v-model="email" />
          <textarea class="texa" cols="30" rows="10" placeholder="Mensaje" v-model="mensaje"></textarea>
          <div class="boton">
            <div class="bloqueaviso">
              <input class="inpcheck" type="checkbox" v-model="aviso" />
              <p class="txtaviso">
                He leído el <a href="#">Aviso de Privacidad</a> antes de enviar el
                formulario.
              </p>
            </div>
            <button type="submit" class="btnform">
              <p class="txtbtn">Enviar</p>
            </button>
          </div>

          <div v-if="this.status == 'success'">
            <p class="cart_msg cart_success">{{ this.msg }}</p>
          </div>
          <div v-if="this.status == 'error'">
            <p class="cart_msg cart_error">{{ this.msg }}</p>
          </div>
        </div>
      </div>
    </form>
    <Footer />
  </div>
</template>
<script>
import Navigator from "../components/Navigator.vue";
import whatsapp from "../components/whatsapp.vue";
import Footer from "../components/Footer.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Home",
  components: {
    Carousel,
    whatsapp,
    Slide,
    Navigator,
    Footer,
  },
  data() {
    return {
      itemsorted: [],
      items: [
        {
          image: require("../assets/imgservicioslide.png"),
          titulo: "Clases particulares",
          descripcion:
            "Perfecciona tus golpes, acelera tu proceso de aprendizaje, de manera particular a tu ritmo y en el horario que mejor se ajuste a tus necesidades.",
        },
        {
          image: require("../assets/imgservicioslide2.png"),
          titulo: "Clases grupales",
          descripcion:
            "Tenemos grupos para todas las edades y niveles, desde aprender nociones básicas del juego como empuñar la raqueta hasta aprender estrategias de juego, acompañado de jugadores de tu nivel que quieren seguir mejorando. ",
        },
        {
          image: require("../assets/imgservicioslide3.png"),
          titulo: "Clases particular en grupo",
          descripcion:
            "Reunete con dos o tres amigos para en conjunto perfeccionar tus golpes, acelerar su proceso de aprendizaje y en el horario que mejor se ajuste a sus necesidades, a un precio mas economico y exclusivo para tu grupo.",
        },
        // {
        //   image: require("../assets/imgservicioslide.png"),
        //   titulo: "Cursos intensivos",
        //   descripcion:
        //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy.",
        // },
        {
          image: require("../assets/imgservicioslide1.png"),
          titulo: "Talleres",
          descripcion:
            "Eventos especiales en los cuales se trabajan temas puntuales para mejorar tu nivel competitivo. Mejora tu saque, mejora tu devolucion, se mas agresivo y defensa/contragolpe, son algunos de los talles que tendremos en el año. ",
        },
        {
          image: require("../assets/imgservicioslide4.png"),
          titulo: "Acondicionamiento fisico para Tenis",
          descripcion:
            "Prepárate para competir como un verdadero profesional, recibe un entrenamiento físico especializado para Tenis, aumenta tu resistencia, tu explosividad en cancha, mejora tu flexibilidad y fuerza.",
        },
      ],
      hide: false,
      aviso: false,
      name: "",
      phone: "",
      email: "",
      mensaje: "",
      status: "",
      msg: "",
    };
  },
  methods: {
    NArrays(array, number) {
      console.log("3 " + array.length);
      if (this.itemsorted.length < array.length) {
        number += 2;
        this.itemsorted = array.slice(0, number);
        console.log("2 " + number);
      }
      if (number >= array.length) {
        this.hide = true;
      }
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
      /*this.close(), 3000);*/
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },
    enviarMail: async function (name, phone, email, mensaje) {
      this.msg = "";
      this.status = "";

      if (this.aviso == false) {
        this.msg = "Acepta los términos y condiciones.";
        this.status = "error";
      } else if (name == undefined || name == "" || name == null) {
        this.msg = "El campo nombre no es valido.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "El campo medio no es valido.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "El campo correo no es valido.";
        this.status = "error";
      } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
        this.msg = "El campo comentarios no es valido.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          mensaje: mensaje,
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );
        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          (this.phone = ""), (this.email = ""), (this.mensaje = "");
          this.delStatus();
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
  mounted() {
    this.itemsorted = this.items.slice(0, 2);
  },
};
</script>
<style scoped>
.pc {
  display: none;
}

.mo {
  display: block;
}

.section1 {
  background: none;
}

/* Banner Movil */
.bannermv {
  width: 100vw;
  height: 145.41062801932367vw;
}

.bannerpc {
  display: none;
}

/* Cambiar fondo movil nosotros aqui */
.filanosotros {
  background: url(../assets/fondos1mv.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -1vw;
}

.idsecc {
  display: flex;
  width: 70%;
  margin: auto;
  padding-top: 14.492753623188406vw;
}

.imgqs1pc {
  display: none;
}

.imgqs1mv {
  width: 100vw;
  height: 67.14975845410628vw;
}

.idsecc h5 {
  font-size: var(--fs-idseccionmv);
  font-family: "Monument-regular";
  margin: 0;
}

.idsecc hr {
  width: 14.975845410628018vw;
  border: 1.5px solid var(--co-hr1seccion);
  margin-top: 1vw;
  background: var(--co-hr1seccion);
}

.bloqueqs {
  text-align: center;
  padding-top: 16.425120772946862vw;
}

.bloqueqs h2 {
  font-size: var(--fs-t1nosomv);
  color: var(--co-t1noso);
  font-family: var(--font-titulo1);
  line-height: 7.64975845410628vw;
  letter-spacing: 0.03em;
}

.bloqueqs h4 {
  font-size: var(--fs-p1nosomv);
  font-family: var(--font-parrafo);
  line-height: 5.021739130434782vw;
  color: var(--co-p1noso);
  text-align: left;
  width: 75%;
  margin: auto;
  margin-bottom: 8.695652173913043vw;
}

.bloqueqs p {
  font-family: var(--font-parrafo);
  font-size: var(--fs-p2nosomv);
  color: var(--co-p2noso);
  text-align: left;
  width: 75%;
  margin: auto;
  margin-bottom: 4.5893719806763285vw;
}

/* Seccion Servicios */
.sectionservicios {
  background: var(--co-bgservicios);
}

.sectionservicios .idsecc h5 {
  color: var(--co-h52seccion);
}

.titservicios {
  text-align: center;
  color: var(--co-t1serv);
  margin: 0;
  margin-top: 20.048309178743963vw;
  text-transform: uppercase;
  font-size: var(--fs-t1servmv);
  font-family: var(--font-titulo1);
}

.subservicios {
  text-align: center;
  color: var(--co-t2serv);
  font-size: var(--fs-t2servmv);
  line-height: 5.021739130434782vw;
  width: 80%;
  margin: auto;
  margin-top: 9.903381642512077vw;
  margin-bottom: 10.38647342995169vw;
}

.bloqueservicio {
  text-align: center;
}

.bloqueservicio img {
  width: 68.11594202898551vw;
  height: 52.65700483091788vw;
}

.bloqueservicio h3 {
  color: var(--co-h3serv);
  font-size: var(--fs-h3servmv);
  font-family: var(--font-titulo1);
  width: 70%;
  margin: auto;
  margin-top: 4.3478260869565215vw;
  line-height: 5.898550724637682vw;
}

.bloqueservicio p {
  text-align: left;
  width: 60%;
  margin: auto;
  margin-top: 4.3478260869565215vw;
  font-size: var(--fs-pservmv);
  font-family: var(--font-parrafo);
  color: var(--co-pserv);
  margin-bottom: 5.314vw;
  /**/
  line-height: 4.826086956521739vws;
}

.bloquevermas {
  text-align: center;
  margin-top: 12.734299516908212vw;
  padding-bottom: 16.425120772946862vw;
}

.bloquevermas p {
  color: var(--co-btnserv);
  font-family: var(--font-parrafo);
}

/* Seccion contacto */
.col1-s3 {
  display: none;
}

.col2-s3 {
  background-image: url(../assets/fondocontacto.svg);
  background-size: 100%;
}

.sectioncontacto .idsecc hr {
  background: var(--co-hr2seccion);
  border: 1.5px solid #fa8f21;
}

.sectioncontacto h2 {
  text-align: center;
  margin: 0;
  font-size: var(--fs-titcontamv);
  text-transform: uppercase;
}

.sectioncontacto .titc1 {
  font-family: var(--font-titulo2);
  margin-top: 23.91304347826087vw;
  color: var(--co-titconta1);
  font-size: var(--fs-titconta1mv);
  line-height: 7.64975845410628vw;
}

.sectioncontacto .titc2 {
  font-family: var(--font-titulo1);
  color: var(--co-titconta);
  font-size: var(--fs-titcontamv);
  line-height: 9.83574879227053vw;
}

.sectioncontacto h4 {
  text-align: center;
  font-family: var(--font-parrafo);
  font-size: var(--fs-subcontamv);
  font-weight: 400;
  width: 80%;
  margin: auto;
  margin-top: 4.1062801932367154vw;
  line-height: 5.579710144927537vw;
}

.sectioncontacto h4 span {
  font-weight: 600;
}

.filadacon {
  margin-top: 9.66183574879227vw;
  text-align: center;
}

.filadacon img {
  width: 19.32367149758454vw;
  height: 19.32367149758454vw;
}

.filadacon p {
  font-family: var(--font-parrafo);
  font-size: var(--fs-pacontamv);
  font-weight: 400;
  margin: 0;
  margin-top: 3.5vw;
  margin-bottom: 16.425120772946862vw;
}

.filadacon p span {
  font-weight: 600;
}

.filadaubi {
  text-align: center;
  padding-bottom: 16vw;
}

.filadaubi img {
  margin-bottom: 3.5vw;
  width: 19.32367149758454vw;
  height: 19.32367149758454vw;
}

.filadaubi p {
  font-family: var(--font-parrafo);
  font-size: var(--fs-pacontamv);
  font-weight: 400;
  margin: 0;
  line-height: 5.36231884057971vw;
}

.nameubi {
  font-weight: 700 !important;
}

.direubi {
  width: 80%;
  margin: auto !important;
  margin-bottom: 3.5vw !important;
}

.mapamv {
  height: 78.74396135265701vw;
}

/* Seccion Siguenos */
.sectionredes {
  background-image: url(../assets/fondos1.svg);
  padding-bottom: 11.11111111111111vw;
}

.sectionredes h2 {
  font-family: var(--font-titulo1);
  font-weight: 800;
  text-align: center;
  font-size: var(--fs-titsigmv);
  margin-top: 20.048309178743963vw;
  text-transform: uppercase;
}

.fila-br {
  width: 89%;
  margin: auto;
  margin-bottom: 3.3vw;
}

.fila-br img {
  width: 41.21256038647343vw;
  height: 41.21256038647343vw;
}

.img2br {
  margin-left: 5vw;
}

.btnig {
  display: flex;
  background-image: url(../assets/logoig.svg);
  background-color: #faf5fc;
  background-size: 7.770531400966184vw 8.007246376811594vw;
  background-repeat: no-repeat;
  background-position-x: 05%;
  background-position-y: 50%;
  text-align: center;
  width: 85%;
  margin: auto;
}

.btnig h5 {
  width: 100%;
  text-align: center;
  border-radius: 1.4492753623188406vw;
  font-family: var(--font-parrafo);
  font-size: var(--fs-btnigmv);
  margin: 0;
  margin-top: 4.830917874396135vw;
  margin-bottom: 4.830917874396135vw;
}

.imgban2pc {
  display: none;
}

.imgban2mv {
  width: 100%;
  height: 51.20772946859904vw;
}

/* Seccion Formulario */
.sectionformulario {
  padding-top: 20.77294685990338vw;
  padding-bottom: 17.632850241545896vw;
}

.sectionformulario h2 {
  text-align: center;
  font-family: var(--font-titulo1);
  font-size: var(--fs-titformmv);
  color: var(--co-titformmv);
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  margin-bottom: 12.560386473429952vw;
}

.bloqueform {
  width: 80%;
  margin: auto;
}

.inputbf {
  width: 100%;
  height: 14.975845410628018vw;
  margin-bottom: 3.3816425120772946vw;
  border: 1.5px solid #000000;
  text-indent: 5%;
  font-family: var(--font-parrafo);
  font-size: var(--fs-inpformmv);
}

.texa {
  width: 100%;
  margin-bottom: 3.3816425120772946vw;
  border: 1.5px solid #000000;
  text-indent: 5%;
  padding-top: 5vw;
  font-family: var(--font-parrafo);
  font-size: var(--fs-inpformmv);
  line-height: 4.676328502415459vw;
}

.bloqueaviso {
  display: flex;
}

.bloqueaviso .inpcheck {
  width: 3.6231884057971016vw;
  height: 3.6231884057971016vw;
  margin-right: 2.5vw;
}

.bloqueaviso .txtaviso {
  margin: 0;
  font-family: var(--font-parrafo);
  font-size: var(--fs-adpformmv);
  color: var(--co-adpform);
  line-height: 7.5724637681159415vw;
  margin-bottom: 9.420289855072465vw;
}

.txtaviso a {
  color: var(--co-linkadpform);
  text-decoration: underline;
}

button.btnform {
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
}

.btnform p {
  background: var(--co-btnform);
  color: var(--co-pabtnform);
  text-align: center;
  font-family: var(--font-parrafo);
  font-size: var(--fs-pabtnformmv);
  font-weight: 700;
  padding: 7.487922705314009vw 0;
  border-radius: 12vw;
  margin: 0;
}

@media (min-width: 1024px) {
  .pc {
    display: block;
  }

  .mo {
    display: none;
  }

  /* Cambiar fondo Seccion 1 aqui */
  .section1 {
    background-image: url(../assets/fondos1.svg);
    background-size: cover;
  }

  .filanosotros {
    background: none;
  }

  .filabanner {
    text-align: left;
    margin: auto;
  }

  .bannermv {
    display: none;
  }

  .bannerpc {
    display: block;
    width: 89.73958333333333vw;
    height: 38.4375vw;
    margin: auto;
  }

  .imgqs1pc {
    display: block;
  }

  .imgqs1mv {
    display: none;
  }

  .idsecc {
    display: flex;
    width: 17%;
    margin: auto;
    padding-top: 6.5vw;
  }

  .idsecc h5 {
    font-size: var(--fs-idseccionpc);
    margin: 0;
  }

  .idsecc hr {
    width: 3.229166666666667vw;
    background: var(--co-hr1seccion);
    border: 1px solid var(--co-hr1seccion);
    margin-top: 0.35vw;
  }

  .bloqueqs {
    padding-top: 6.822916666666666vw;
    padding-left: 6.979166666666667vw;
    padding-bottom: 4vw;
    display: flex;
  }

  .imgqs1pc {
    width: 37.135416666666664vw;
    height: 35.989583333333336vw;
  }

  .col2-qs {
    padding-left: 5.885416666666667vw;
  }

  .bloqueqs h2 {
    padding-top: 5vw;
    font-size: var(--fs-t1noso);
    text-align: left;
    line-height: 2.3567708333333335vw;
    margin-bottom: 5vw;
  }

  .bloqueqs h4 {
    margin: 0;
    font-size: var(--fs-p1noso);
    line-height: 1.203125vw;
    margin-bottom: 3vw;
    margin-left: 4vw;
    width: 74%;
  }

  .bloqueqs p {
    font-size: var(--fs-p2noso);
    margin: 0;
    width: 32%;
    line-height: 0.9989583333333333vw;
    float: left;
    margin-left: 4vw;
  }

  .bloqueqs p:last-child {
    margin-left: 3.5vw;
  }

  /* Seccion Servicios */
  .titservicios {
    margin-top: 8.75vw;
    font-size: var(--fs-t1serv);
    letter-spacing: 0.05em;
    line-height: 3.440625vw;
  }

  .subservicios {
    font-size: var(--fs-t2serv);
    line-height: 3.440625vw;
    margin: 0;
    margin-top: 1.54166666666665vw;
    text-align: center;
    width: 100%;
  }

  .slideservicios {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 3.125vw;
    padding-bottom: 9.427vw;
  }

  .bloqueservicio img {
    width: 11.354166666666666vw;
    height: 11.354166666666666vw;
  }

  .bloqueservicio h3 {
    font-size: var(--fs-h3serv);
    line-height: 1.0984375vw;
    width: 60%;
    margin: auto;
    margin-top: 0.9375vw;
  }

  .bloqueservicio p {
    text-align: left;
    width: 80%;
    margin: auto;
    margin-top: 0.9375vw;
    font-size: var(--fs-pserv);
    color: var(--co-pserv);
    /*margin-bottom: 5.314009661835748vw;*/
    line-height: 0.8093750000000001vw;
  }

  .bloquevermas {
    display: none;
  }

  /* Seccion Contacto */
  .col1-s3 {
    display: block;
  }

  .mapamv {
    display: none;
  }

  .sectioncontacto {
    display: flex;
  }

  .col1-s3 {
    width: 42%;
  }

  .col2-s3 {
    width: 58%;
    padding-left: 8.333333333333332vw;
  }

  .sectioncontacto .idsecc {
    margin: 0;
    width: 27%;
  }

  .sectioncontacto .titc1 {
    margin-top: 9.270833333333334vw;
    font-size: var(--fs-titconta1);
    line-height: 2.5921875vw;
    text-align: left;
  }

  .sectioncontacto .titc2 {
    font-size: var(--fs-titconta);
    line-height: 2.5921875vw;
    text-align: left;
  }

  .sectioncontacto h4 {
    text-align: left;
    font-size: var(--fs-subconta);
    font-weight: 400;
    width: auto;
    margin-top: 1.4583333333333333vw;
    line-height: 1.203125vw;
    margin-left: 4vw;
  }

  .filadacon {
    display: flex;
    margin-top: 2.604166666666667vw;
    margin-left: 4vw;
  }

  .col1-dc,
  .col2-dc,
  .filadaubi {
    display: flex;
  }

  .col2-du {
    margin-top: 1.8vw;
  }

  .filadacon img,
  .filadaubi img {
    width: 4.427083333333334vw;
    height: 4.427083333333334vw;
    margin-right: 1.5625vw;
  }

  .filadacon p {
    font-size: var(--fs-paconta);
    font-weight: 400;
    margin: 0;
    margin-top: 1.8vw;
    margin-bottom: 2vw;
    margin-right: 3vw;
  }

  .filadaubi {
    margin-left: 4vw;
    margin-top: 1.875vw;
  }

  .filadaubi p {
    font-size: var(--fs-paconta);
    margin: 0;
    line-height: 1.36231884057971vw;
    text-align: left;
  }

  .direubi {
    width: 50%;
    margin: 0 !important;
    margin-bottom: 2vw !important;
  }

  /* Seccion siguenos */
  .sectionredes .idsecc {
    padding-top: 4.479166666666667vw;
  }

  .sectionredes h2 {
    font-weight: 800;
    text-align: left;
    font-size: var(--fs-titsig);
    margin-top: 4.78125vw;
    text-transform: uppercase;
  }

  .fila-br img {
    width: 14.603645833333331vw;
    height: 14.603645833333331vw;
  }

  .img2br {
    margin-left: 1.6052083333333336vw;
  }

  .fila-br {
    width: 100%;
    margin: auto;
    margin-bottom: 1.6052083333333336vw;
  }

  .sectionredes {
    background-image: url(../assets/fondoredes.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-bottom: 2vw;
  }

  .bloqueredes {
    padding-left: 18.802083333333332vw;
  }

  .btnig {
    display: flex;
    background-image: url(../assets/logoig.svg);
    background-color: #faf5fc;
    background-size: 1.1989583333333333vw 1.2171875vw;
    background-repeat: no-repeat;
    background-position-x: 05%;
    background-position-y: 50%;
    text-align: center;
    width: 17%;
    margin: 0;
  }

  .btnig h5 {
    width: 100%;
    text-align: center;
    border-radius: 1.4492753623188406vw;
    font-size: var(--fs-btnig);
    margin: 0;
    margin-top: 0.6770833333333334vw;
    margin-bottom: 0.6770833333333334vw;
  }

  .imgban2pc {
    display: block;
    width: 100%;
    height: 16.614583333333332vw;
  }

  .imgban2mv {
    display: none;
  }

  /* Seccion Formulario */
  .sectionformulario {
    padding-top: 8.072916666666668vw;
    padding-bottom: 5.989583333333334vw;
  }

  .sectionformulario h2 {
    font-size: var(--fs-titform);
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
    margin-bottom: 5.989583333333334vw;
  }

  .bloqueform {
    width: 77vw;
  }

  .inputbf {
    width: 100%;
    height: 4.166666666666666vw;
    margin-bottom: 2.4479166666666665vw;
    border: 1.5px solid #000000;
    text-indent: 2.5%;
    font-size: var(--fs-inpform);
  }

  .inputname {
    width: 36.145833333333336vw;
    margin-right: 1.4583333333333333vw;
  }

  .inputnumber {
    width: 17.291666666666668vw;
    margin-right: 1.4583333333333333vw;
  }

  .inputemail {
    width: 17.395833333333332vw;
  }

  .texa {
    width: 98%;
    margin-bottom: 2.1354166666666665vw;
    border: 1.5px solid #000000;
    text-indent: 2.5%;
    padding-top: 2vw;
    font-size: var(--fs-inpform);
    line-height: 1.0083333333333333vw;
  }

  .bloqueaviso {
    width: 50%;
  }

  .bloqueaviso .inpcheck {
    width: 0.8333333333333334vw;
    height: 0.8333333333333334vw;
    margin-right: 0.6770833333333334vw;
  }

  .bloqueaviso .txtaviso {
    margin: 0;
    font-size: var(--fs-adpform);
    line-height: 1.2703125000000002vw;
    margin-bottom: 0vw;
  }

  .boton {
    display: flex;
    justify-content: space-between;
  }

  button.btnform {
    width: 30%;
    border: none;
    background: transparent;
  }

  .btnform p {
    text-align: center;
    font-size: var(--fs-pabtnform);
    font-weight: 700;
    padding: 1.1979166666666667vw 0;
    border-radius: 12vw;
  }
}
</style>