<template>
  <div class="bloquefooter">
    <div class="fila1footer">
      <div class="colmenu">
        <div class="menufooter">
          <h5>MENÚ</h5>
          <ul>
            <li><a @click="navigation('/')">INICIO</a></li>
            <li><a @click="navigation('nosotros')">NOSOTROS</a></li>
            <li><a @click="navigation('academia')">ACADEMIA</a></li>
            <li><a @click="navigation('clase-de-prueba')">CLASE DE PRUEBA</a></li>
            <li><a @click="navigation('contacto')">CONTACTO</a></li>
          </ul>
        </div>
        <img class="imgfoomv" src="../assets/imgfootermv.svg" />
        <img class="imgfoopc" src="../assets/imgfooter.svg" />
      </div>
      <div class="infofooter">
        <h5>INFORMACIÓN</h5>
        <div class="bloqueiff">
          <p class="titpif">Dirección</p>
          <p class="txtpif">Blvd. Navarrete</p>
          <p class="txtpif">Montebello, 83210</p>
          <p class="txtpif ultpif">Hermosillo, Sonora</p>

          <p class="titpif">Teléfono</p>
          <a href="tel:6624055628">
            <p class="txtpif ultpif">(662) 405 5628</p>
          </a>

          <p class="titpif">Horario atencion a clientes</p>
          <p class="txtpif ultpif">Lun a Sab 10 am a 5 pm</p>
        </div>
        <div class="redesfooter">
          <a href="https://www.facebook.com/playtennismx"><img src="../assets/fbf.svg" alt="Icono Facebook" /></a>
          <a class="btnigfo" href="https://www.instagram.com/playtennismx"><img src="../assets/IGf.svg"
              alt="Icono Instagram" /></a>
        </div>
      </div>
      <div class="newsfooter">
        <div class="fila1newsfo">
          <form @submit.prevent="suscribirse(email)">
            <h5 class="titnewslfo">Suscríbete a nuestro newsletter</h5>
            <div class="bloquenewsletter">
              <input type="email" name="" id="" placeholder="Correo electrónico" v-model="email" />
              <button type="submit" class="submitnewsl-btn">Enviar</button>
            </div>
            <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.message }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.message }}</p>
            </div>
          </form>
        </div>
        <div class="fila2newsfo">
          <div class="col1nefo">
            <a href="/aviso-de-privacidad">
              <p>Aviso de Privacidad</p>
            </a>
          </div>
          <div class="col2nefo">
            <img src="../assets/logofooter.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="fila2footer">
      <p><span>Copyright 2024</span> © Todos los Derechos Reservados.</p>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      email: "",
      status: "",
      message: "",
    };
  },
  methods: {
    suscribirse: async function (email) {
      this.status = "";
      this.message = "";
      let sub_date = dayjs().format("YYYY-MM-DD");
      let data = {
        email,
        sub_date,
      };

      let result = await this.$store.dispatch("suscripciones/addItemSbs", {
        option: "suscripciones",
        item: data,
      });

      if (result.status == "error") {
        this.status = "error";
        this.message = result.message;
        console.log(this.message)
        this.delStatus();
      } else {
        // success
        this.status = "success";
        this.message = "Suscripción exitosa!";
        console.log(this.message)
        this.delStatus();
      }
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 3000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
    },

    navigation(link) {
      if (this.$route.name === 'home' && link !== '/') {
        window.location.href = `#${link}`;
      } else if (link === '/') {
        window.location.href = "/"
      } else {
        this.$router.push("/")

        setTimeout(() => {
          window.location.href = `#${link}`;
        }, 600);
      }
    }
  },
};
</script>
<style scoped>
/* FOOOOTER */
a {
  cursor: pointer;
}
.bloquefooter {
  background: #1a1f98;
  padding-top: 13.285024154589372vw;
}

.fila1footer h5 {
  font-family: var(--font-titulo1);
  text-align: center;
  color: var(--co-titsecfo);
  font-size: var(--fs-titsecfomv);
  font-weight: 800;
}

.colmenu {
  text-align: center;
}

.colmenu ul {
  margin: 0;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0 !important;
}

.colmenu li {
  font-family: var(--font-parrafo);
  font-size: var(--fs-txtmenufomv);
  color: var(--co-txtmenufo);
  text-align: center;
  list-style: none;
  line-height: 13.553140096618357vw;
}

.imgfoopc {
  display: none;
}

.colmenu img {
  margin-top: 5vw;
  width: 71.43719806763285vw;
  height: auto;
  margin-bottom: 13.768115942028986vw;
}

.bloqueiff {
  text-align: center;
}

.bloqueiff p {
  font-family: var(--font-parrafo);
  font-size: var(--fs-pinfofomv);
  margin: 0;
  line-height: 5.36231884057971vw;
}

.titpif {
  color: var(--co-titinfofo);
}

.txtpif {
  color: var(--co-txtinfofo);
}

.ultpif {
  margin-bottom: 9.66183574879227vw !important;
}

.redesfooter {
  text-align: center;
}

.redesfooter img {
  width: 15.268115942028986vw;
  height: 15.268115942028986vw;
}

.btnigfo {
  margin-left: 5vw;
}

.infofooter {
  width: 86%;
  padding-bottom: 20.596618357487923vw;
  border-bottom: 1px solid #55cd00;
  margin: auto;
}

.newsfooter {
  width: 85%;
  margin: auto;
  padding-top: 11.83574879227053vw;
}

.fila1newsfo {
  padding-bottom: 17.632850241545896vw;
  border-bottom: 1px solid #55cd00;
}

.titnewslfo {
  font-family: var(--font-parrafo) !important;
  text-transform: uppercase;
  text-align: left !important;
  margin: 0;
  margin-bottom: 6.246376811594203vw;
}

.bloquenewsletter {
  height: 20.806763285024154vw;
}

.newsfooter input {
  width: 60%;
  height: 20.806763285024154vw;
  background: transparent;
  border: 1.5px solid #55cd00;
  border-top-left-radius: 7.246376811594203vw;
  border-bottom-left-radius: 7.246376811594203vw;
  text-indent: 5%;
  color: #ffffff !important;
}

.newsfooter input::placeholder {
  color: #ffffff;
  font-family: var(--font-parrafo);
  font-weight: 300;
  font-style: italic;
}

.newsfooter button {
  background: #55cd00;
  font-family: var(--font-parrafo);
  font-weight: 300;
  height: 22.2vw;
  border: 1.5px solid #55cd00;
  width: 35%;
  color: #ffffff;
  cursor: pointer;
  border-top-right-radius: 7.246376811594203vw;
  border-bottom-right-radius: 7.246376811594203vw;
}

.fila2newsfo {
  display: flex;
  flex-direction: column-reverse;
}

.col2nefo {
  text-align: center;
  padding-top: 8.937198067632849vw;
}

.col2nefo img {
  width: 45.169082125603865vw;
  height: 22.946859903381643vw;
}

.col1nefo {
  text-align: center;
  display: block;
}

.col1nefo a {
  width: 100%;
  color: var(--co-titinfofo);
  font-family: var(--font-parrafo);
  font-size: var(--fs-pinfofomv);
}

.col1nefo a p {
  margin: 0;
  margin-top: 3.5816425120772946vw;
}

.fila2footer {
  margin: auto;
  width: 85%;
  padding-bottom: 9.66183574879227vw;
}

.fila2footer p {
  color: var(--co-txtcopy);
  font-family: var(--font-parrafo);
  font-size: var(--fs-txtcopymv);
  text-align: center;
  margin: 0;
  margin-top: 5.314009661835748vw;
}

.cart_msg {
  position: relative;
  top: 2vw;
}

@media (min-width: 1024px) {
  .bloquefooter {
    padding-top: 3.802083333333333vw;
  }

  .fila1footer {
    display: flex;
    width: 85%;
    border-bottom: 0px solid #55cd00;
    margin: auto;
    padding-left: 8.854166666666668vw;
    height: 20.260416666666668vw;
  }

  .colmenu {
    width: 27%;
    display: flex;
    border-right: 1px solid #55cd00;
    padding-top: 0.8854166666666666vw;
  }

  .menufooter h5 {
    margin-bottom: 1.7187500000000002vw !important;
  }

  .infofooter {
    width: 22%;
    border-right: 1px solid #55cd00;
    margin: 0;
    padding-left: 4.270833333333333vw;
    padding-top: 0.8854166666666666vw;
    padding-bottom: 0vw;
    border-bottom: 0px solid #55cd00;
  }

  .newsfooter {
    width: 40%;
    padding-top: 0.8854166666666666vw;
    margin: 0 !important;
    padding-left: 4.270833333333333vw;
  }

  .fila1footer h5 {
    text-align: left;
    font-size: var(--fs-titsecfo);
    font-weight: 800;
    margin: 0;
  }

  .colmenu li {
    font-size: var(--fs-txtmenufo);
    text-align: left;
    list-style: circle !important;
    line-height: 2.0458333333333334vw;
    margin-left: 1.25vw;
  }

  .imgfoomv {
    display: none;
  }

  .imgfoopc {
    display: block;
  }

  .colmenu img {
    margin-top: 0vw;
    width: 10.729166666666666vw;
    height: 18.229166666666664vw;
    margin-bottom: 3.768115942028986vw;
  }

  .bloqueiff p {
    font-size: var(--fs-txtinfofo);
    margin: 0;
    line-height: 0.9250000000000002vw;
    text-align: left;
    font-weight: 400;
  }

  .ultpif {
    margin-bottom: 1.3541666666666667vw !important;
  }

  .redesfooter {
    padding-top: 1.34375vw;
    display: flex;
  }

  .redesfooter img {
    width: 1.875vw;
    height: 1.875vw;
  }

  .btnigfo {
    margin-left: 1vw;
  }

  .infofooter h5 {
    margin-bottom: 1.25vw !important;
  }

  .titnewslfo {
    font-family: var(--font-titulo1) !important;
    font-weight: 800;
    margin-bottom: 1.0416666666666665vw !important;
  }

  .bloquenewsletter {
    height: 2.604166666666667vw;
  }

  .newsfooter input {
    width: 54%;
    height: 2.604166666666667vw;
    border-top-left-radius: 7.246376811594203vw;
    border-bottom-left-radius: 7.246376811594203vw;
    text-indent: 5%;
    color: #ffffff !important;
  }

  .newsfooter button {
    height: 3vw;
    width: 25%;
    color: #ffffff;
    border-top-right-radius: 7.246376811594203vw;
    border-bottom-right-radius: 7.246376811594203vw;
  }

  .fila1newsfo {
    padding-bottom: 0vw;
    border-bottom: 0px solid #55cd00;
  }

  .fila2newsfo {
    display: flex;
    flex-direction: row;
    padding-top: 9.5vw;
  }

  .col1nefo {
    width: 50%;
  }

  .col2nefo {
    width: 50%;
    padding-top: 0vw;
  }

  .col1nefo a {
    width: 30%;
    font-size: var(--fs-txtinfofo);
    text-align: left;
  }

  .col1nefo a p {
    margin: 0;
    margin-top: 0.3125vw;
  }

  .col2nefo img {
    width: 9.739583333333334vw;
    height: 4.947916666666666vw;
    margin-top: -2vw;
  }

  .fila2footer {
    padding-bottom: 2.3958333333333335vw;
    border-top: 1px solid #55cd00;
    margin-top: 2vw;
    width: 92%;
  }

  .fila2footer p {
    font-size: var(--fs-txtcopy);
    text-align: center;
    margin: 0;
    margin-top: 1.5625vw;
    width: 92%;
  }
}
</style>